import Config from '../../config/api-config'
//import Config from './config'
import Request from './request'
import Cart from './cart'
import Category from './category'
import Courier from './courier'
import Product from './product'
import Customer from './customer'
import Contact from './contact'
import Address from './address'
import Profile from './profile'
import OrderHistory from './orderhistory'

import Franchise from './franchise'
import Seminar from './seminar'
import Course from './course'

export default class NegosyoNow {
	constructor(config) {
		this.config = config;
		this.request = new Request(config);
		this.Category =  new Category(this.request);
		this.Courier = new Courier(this.request);
		this.Product =  new Product(this.request);
		this.Contact = new Contact(this.request);
		this.Address = new Address(this.request);
		this.Franchise = new Franchise(this.request);
		this.Seminar = new Seminar(this.request);
		this.Course = new Course(this.request);
		this.OrderHistory = new OrderHistory(this.request);
		this.Customer = new Customer();
		this.Profile = new Profile(this.request);
	}

	// Expose `Cart` class on Moltin class
	Cart(id) {
		return new Cart(this.request, id)
	}

}

//const gateway = () => new NegosyoNow(Config)
const gateway = () => new NegosyoNow(Config)

export { gateway }

