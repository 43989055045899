import { Auth } from 'aws-amplify'

class Course {
	constructor(request) {
		this.request = request
		this.endpoint = 'course'
	}


	Search(category, fail ) {
		return this.request.requestData(`https://cdn.ngnw.ph/lists/${this.endpoint}/category/${category}.json`)
	}

	SearchTop(category) {
		return this.request.requestData(`https://cdn.ngnw.ph/lists/${this.endpoint}/category/top/${category}.json`)
	}

	SearchText(text) {
		const body = {
			"searchtext":text
		}
		return this.request.send(
			`${this.endpoint}/search/`,
			'POST',
			body
		)
	}



	Register(info) {
		return this.request.send(
			`${this.endpoint}/request`,
			'POST',
			info,
			undefined,
			undefined,
			{
				"configkey":"a0226aef26f2e16906efb7c629422c7a"
			}
		)
	}

	async Payment(info) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})

		return this.request.send(
			`${this.endpoint}/payment`,
			'POST',
			info,
			jwtToken,
			undefined,
			{
				"configkey":"c1438bf4dac963f81e3b4f4a457f873d"
			}
		)
	}

	GetDetails(url) {
		return this.request.requestData(
			`https://cdn.ngnw.ph/details/${this.endpoint}/${url}.json`
		)
	}

	GetDetailsQuestions(url) {
		return this.request.requestData(
			`https://cdn.ngnw.ph/details/${this.endpoint}/register/${url}.json`
		)
	}

	async IsRegistered(username, url) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})
		const body = {
			username:username,
			url:url
		}

		return this.request.send(
			`${this.endpoint}/check`,
			'POST',
			body,
			jwtToken
		)
	}

	async AccessResource(username, url, resourceurl) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})
		const body = {
			username:username,
			url:url,
			resource: resourceurl
		}

		return this.request.send(
			`${this.endpoint}/access`,
			'POST',
			body,
			jwtToken
		)
	}

	async GetResources(username, url) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})
		const body = {
			username:username,
			url:url
		}

		return this.request.send(
			`${this.endpoint}/content`,
			'POST',
			body,
			jwtToken
		)
	}


	async DownloadFile(username, url, fname) {
		let jwtToken
		await Auth.currentSession()
		.then((data)=>{
			jwtToken=data.idToken.jwtToken
		})
		.catch ( (err)=> {

		})

		return this.request.downloadSecureData(
			`${this.endpoint}/`+url+`/`+fname,
			jwtToken
		)
	}

}


export default Course